.image-file-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: "50% 50%";
  cursor: pointer;
  border-radius: 0.6rem;
}
