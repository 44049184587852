.admin-container {
  display: flex;
  width: calc(100% - 50px);
  align-items: stretch;
}

.admin-container.is-open{
  width: calc(100% - 280px);
}

.admin-container.closed {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .admin-container {
    width: 100% !important;
  }
}
