.calendar-month-view-container {
  font-family: 'Roboto', sans-serif;
  width: 100%;
}

.calendar-month-view-header {
  padding: 1rem 1rem 0 1rem;
}

.calendar-month-view-body {
  padding: 0.25rem 1.25rem 1.25rem 1.25rem;
}

.calendar-month-view-weeks-container{
  display: flex;
}

.calendar-month-view-week-container{
  width: 14.28571428571429%;
  text-align: center;
  padding: 0.2rem;
  border: 1px solid var(--gray);
  border-radius: 0.25rem;
  margin: 0.25rem
}

/* .calendar-month-view-week{
  color: var(--gray);
} */

.calendar-month-view-days-container {
  display: flex;
  flex-wrap: wrap;
}

.calendar-month-view-day-container {
  width: 14.28571428571429%;
  height: 40px;
  padding: 0.2rem;
}

.calendar-month-view-day {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.25rem !important;
  cursor: pointer;
  /* color: var(--primary); */
  border: 1px solid var(--gray);
  border-radius: 0.25rem;
}

.calendar-month-view-day.today {
  font-weight: 900;
}

.calendar-month-view-day.last-days-of-prev-month {
  color: var(--gray);
  opacity: 0.8;
}

.calendar-month-view-day.first-days-of-next-month {
  color: var(--gray);
  opacity: 0.8;
}

.calendar-month-view-day:hover {
  background-color: var(--warning) !important;
  opacity: 0.8;
}

.calendar-month-view-day.selected {
  background-color: var(--warning) !important;
}

.calendar-month-view-day.holiday {
  background-color: var(--danger);
}
