/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 50px;
  width: 50px;
  background: var(--primary);
  color: var(--white);
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 1.5s;
}

.sidebar-backdrop {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark);
  opacity: 0.8;
  z-index: 9999;
}

.sidebar.is-open {
  min-width: 280px;
  width: 280px;
  margin-left: 0;
  transition: 1.5s;
}

.sidebar.is-open.closed {
  margin-left: -280px;
  transition: 1.5s;
}

.sidebar.closed {
  margin-left: -50px;
  transition: 1.5s;
}

.sidebar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--primary);
}

.sidebar-header img {
  width: 100%;
  max-width: 150px;
  transition: 1s;
}

.sidebar-header h3 {
  color: var(--white);
  padding: 1em;
}

.sidebar ul p {
  color: var(--white);
  padding: 10px;
}

.sidebar ul {
  background-color: var(--white);
  padding-bottom: 0 !important;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 3px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: var(--white);
}

.sidebar-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5rem 0.5rem 0.5rem 0.5rem;
}

.sidebar-footer.is-open {
  justify-content: center !important;
}

.sidebar-footer.is-open a img {
  width: 100%;
  max-width: 16px;
}

.sidebar-footer a + a > img {
  width: 100% !important;
  max-width: 60px !important;
}

.sidebar-footer a + a {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
  -webkit-transition: visibility 0s 1s, opacity 1s linear;
}

.sidebar-footer.is-open a + a {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
  -webkit-transition: opacity 2s linear;
}

.menu-open {
  background: var(--info);
}

.nav-item:hover {
  background: var(--white);
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.side-menu a {
  color: var(--white);
}

.side-menu a.nav-link {
  padding: 0.5rem 0.5rem 0.5rem 0.9rem;
}

.side-menu a:hover {
  color: var(--primary);
}

.side-menu li:hover a{
  color: var(--primary);
}

.side-menu li.active {
  background-color: var(--white);
}

.side-menu li.active a{
  color: var(--primary);
}

.side-menu li.active + li {
  border-top-right-radius: 20px;
}

.items-menu {
  color: var(--white);
  background: var(--primary);
}

@media only screen and (max-width: 768px) {
  .sidebar {
    margin-left: -280px;
    position: fixed;
    z-index: 99999;
    height: 100vh;
  }

  .sidebar-backdrop.isOpen {
    display: block;
  }

  .sidebar.is-open {
    min-width: 280px;
    width: 280px;
    margin-left: 0;
    transition: all 1.5s, height 0s;
  }

  .sidebar.is-open>.sidebar-header span {
    display: unset;
  }

  .side-menu {
    margin-bottom: 50px;
  }

  .side-menu li.active+li {
    border-top-right-radius: 0px;
  }
}
